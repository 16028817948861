.btnq1{

border: none;
background-color: #F2F2F2;
color: #000;
font-family: Work Sans;
font-size: 16px;
font-weight: bold;
text-align: center;
height: 40px;
width: fit-content;

}

.btnq2{
height: 40px;
border: none;
color: #FFFFFF;
font-family: Work Sans;
font-size: 16px;
font-weight: 500;
text-align: center;
background-color: linear-gradient(92.83deg, #59AFFF 5.33%, #2188E7 96.12%);
width: fit-content;
background-color: #59AFFF;

}

.que{
font-family: Work Sans;
font-size: 20px;
font-weight: 500;
line-height: 23.46px;
text-align: left;
color: #2E2E2E;

}

.opt{
font-family: Work Sans;
font-size: 20px;
font-weight: 400;
line-height: 23.46px;
text-align: left;
color: #2E2E2E;
}

.optCard{
    cursor: pointer;
}

.optCard:hover {
    border: 2px solid #2188E7
}

.cardblue{
    width: 161px;
    height: 90px;
    border-radius: 5px 0px 0px 0px;
    background-color: 
    #EFF5FF;

font-family: Work Sans;
font-size: 36px;
font-weight: 600;
text-align: center;
color: #367CFF;

}

.tq1{
    font-family: Work Sans;
font-size: 25px;
font-weight: 600;
    text-wrap: wrap;
}
.tq2{

    font-family: Work Sans;
font-size: 20px;
font-weight: 600;
text-wrap: wrap;
color: 
#8A8C94;

}

.textt{
    font-family: Work Sans;
font-size: 16px;
font-weight: 400;
color: 
#7E7E7E;

}
.textz{
    font-family: Work Sans;
font-size: 25px;
font-weight: 600;
    text-wrap: wrap;
}

.lcard{
    width: fit-content;
    height: 90px;
    border-radius: 5px 0px 0px 0px;
    background-color: 
    #EFF5FF;

font-family: Work Sans;
font-size: 36px;
font-weight: 600;
text-align: center;
color: rgb(19, 1, 1)
}