.page_color {
    background-color: #701DDB;
    height: 100vh;
    overflow: hidden;
} 
.logo_img {
    width: 50vh;
}
.chose_option h3 {
    color: white;
}
.radio_selsect > h2{
   color: white;
}
.two_lang {
    display: inline-block;
}
.hindi_option label,
.hindi_option label h4 {
  color: white;
}
.hindi_option {
   
    padding: 5px;
    background-color: #f2f2f270;
    width: 278px;
    border: 1px solid #000;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}

.english_option label,
.english_option label h4 {
  color: white;
}
.english_option {
   
    padding: 5px;
    background-color: #f2f2f270;
    width: 278px;
    border: 1px solid #000;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}
.done_lang {
    width: 275px;
    padding: 10px;
    font-weight: 600;
    font-size: large;
}
.mbregister_colr {
    background-color: #701DDB;
    /* height: 82vh; */
    border-radius: 100px 0 0 0;
    overflow: hidden;
}
.crack_exam h4 {
    color: white;
}
.exam_line h4{
    float: right;
}
.enter_no p{
    color: white;
    font-size: large;
}
.mb_whole {
    width: 300px !important;
}
.mob_chg {
    opacity: .4;
}
.age_confirm {
    font-size: small;
    margin-left: 5px;
    color: white;

}
.bot_img img {
    width: 760px;
}
.otp-container {
    display: flex;
    justify-content: center;
    opacity: .7;
  }
  
  .otp-input {
    width: 40px;
    height: 40px;
    margin: 0 5px;
    font-size: 20px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  .bot1_img img {
    width: 650px;
}

.otp-input::-webkit-outer-spin-button,
.otp-input::-webkit-inner-spin-button,
.contact-num-input::-webkit-outer-spin-button,
.contact-num-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.name_banner {
    background-color: #701DDB;
    height: 47vh;
    position: relative;
}
.new_famtext p {
    color: white;
}
.bot_nameimg {
    position: absolute; /* Set image position to absolute */
    bottom: -30px;
    right: 0;
    
}
.bot_nameimg img {
    width: 230px;
    float: right;  
}
.name_enterinput {
    width: 500px;
}
.next_butt {
    width: 500px;
}
.back_tootp img {
    background-color: #e0d6d685;
    padding: 2px;
    border-radius: 50%;
}
.user_imgcat {
    cursor: pointer;
}


   /* final steps css start */
   .final_steps {
    height: 100vh;
    background-color: #701DDB;
    
   }
   .final_banner {
   height: 20vh;
   position: relative;
   
}
.side_flowr {
    position: absolute;
    right: 0;
    z-index: 1;
    top: 50%;
}
.side_flowr img {
   height: 100px;
}
.bot_selectcat {
    height: 68vh;
    background-color: white;
    position: relative;
    z-index: 2;
    border-radius: 50px 50px 0 0;
    overflow: auto;
}
.start_prep {
    height: 12vh;
    background-color: #fff;
    
}
.t2{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}
.next_butt1 {
    width: 500px;
}
.bot_selectcat::-webkit-scrollbar {
    width: 0; /* For Chrome, Safari, and Opera */
  }

.active-gender{
    border: 1px solid purple;
    border-radius: 15px;
}
.notsaved{
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  .no-select {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  
  /* Disable text highlighting */
  .no-highlight {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
  }

/* Responsive Css query start */

@media screen and (max-width: 800px) { 
    .earth_animat {
        height: 200px;
    }
    .logo_img {
        width: 90%;
    }
    .lang_tag {
        font-size: 18px !important;
    }
    .bot_img img {
        width: 100% !important;
    }
    .crack_exam {
        padding-left: 50px;
    }
    .bot1_img img {
        width: 100%;
    }
    .name_enterinput {
        width: 80% !important;
    }
    .next_butt {
        width: 80% !important;
    }
    .bot_nameimg img {
        width: 50% !important;
       
    }
    .bot_nameimg {
        bottom: -23px !important;
    }
    .user_imgcat {
        width: 100%;
    }
    .next_butt1 {
        width: 80% !important;
    }
}