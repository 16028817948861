.btn1{
color: #367CFF;
background-color: #EFF5FF;
font-family: Inter;
font-size: 14px;
font-weight: 400;
border: none;
}

.btn2{
background: linear-gradient(91.08deg, #54ACFD 4.74%, #2289E7 91.89%);
width: 100%;
height: 45px;
font-size: 13px;
color: #FFFFFF;
font-weight: 700
}


.btn3{
    background: #FFFFFF;
width: 100%;
height: 45px;
font-size: 13px;
color:  #BD3DD2;
font-weight: 700;
border: 1px solid #BD3DD2
;
}


.btn4{
    background: #50258D;
width: 100%;
height: 45px;
font-size: 13px;
color:  
#FFFFFF;
font-weight: 700;
;
}


.t1{
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    color: #000000;

}
.t2{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}
.t3{
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    color: #000000;
}
.t4{
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    color: #000000;
}

.t0{
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    color: #8A8A8A;
    cursor: pointer;
}

.t5{
font-size: 14px ;
color: #F0F0F066;
}

.card1{
    box-shadow: 4px 10px 10px 4px #F2F2F2;
    border: 1px solid #F2F2F2;

}

.card2{
 box-shadow: 4px 10px 10px 4px #4741411a;
 border: 1px solid #F2F2F2;
 background: #FFFFFF;
}

.card3{
 background: linear-gradient(103.33deg, #16054F 3.75%, #430E80 96.15%);
 box-shadow: 0px 4px 8px 0px #641BD240;
border: none;
}

.imp{
    font-weight: 600 !important;
}

.tgr{
    color: #7E7E7E;
    font-family: Inter;
font-size: 14px;
font-weight: 400;
}
.tgo{
font-family: Inter;
font-size: 14px;
font-weight: 400;
color: #F5B807;
}
.btnr2_myexamsstudy{
    background:  #FFF4F4;
    width: 100%;
    height: 45px;
    font-size: 13px;
    color: #323636;
    font-weight: 700;
    border: 1px solid #b39696;
    }
/* @media (max-width: 768px) {
    .example1 {
       padding-bottom: 45px !important;
    } 
  } */