.brown{
    ::-webkit-scrollbar {
        display: none;
    }
    
}

.video-container {
    position: relative; 
  }
  
  .arrow-container {
    position: fixed; 
    top: 10px; 
    left: 10px; 
  }


 