.arrowb{
  background-color: #FFFFFF1A;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.vs{
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 500;
  background-color: 
  #FFFFFF;

}
.btnanst2{
  background:  #FFFFFF;
  ;
  width: 100%;
  height: 45px;
  font-size: 13px;
  color: #000000;
  font-weight: 700
  }


  .mytable th {
      color: grey; /* Color all th elements grey */
      border-right: 2px solid #D8D8D8;; /* Add vertical border */
      text-align: center;
      background-color: #D8D8D8;
    }
    
    .mytable tbody {
      font-weight: 600; /* Set font weight of tbody to 500 */
    }
    
    .mytable td {
      text-align: center; /* Center data in each cell */
      border-right: 2px solid #D8D8D8;; /* Add vertical border */
      font-weight: 600; 
    }


    
  .btnanst3{
      background: #FFFFFF;
  width: 100%;
  height: 45px;
  font-size: 13px;
  color:  #367CFF;
  font-weight: 700;
  border: 1px solid #367CFF
  ;
  }
  .modal-backdrop.blur-background {
    backdrop-filter: blur(10px);
  }