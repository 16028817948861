.boxshadow{
  box-shadow: 1px 4px 10px -1px rgba(138 131, 131, 0.7);

}

.btn1{
  background-color: #2E2E2E;
  color:#ffff;
  border-radius: 10px;
}

.btnn{
  background-color: #EFEFEF;
  color:#000;
  border: none;
  border-radius: 10px;
}

.searchbox{
  background-color: #EFEFEF;
}
.soon{
  background-color: #367CFF;
  color:#ffff;
  border-radius: 10px;
  border: none;
}

.save{
  width: 66px;
height: 30px;
color:#367CFF;
border: none;
border-radius: 10px;
font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 19.36px;
text-align: center;
background-color:#EFF5FF;
border: 1px solid #367CFF;
}

.addbtn{
  color: #367CFF;
  background-color:#EFF5FF;
  border: 1px solid #367CFF;
}

.notsaved{
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
@media (max-width: 768px) {
  .heading {
    font-size: 20px !important;
  } 
}