.custom-input {
  border-color: #F5F5F5 !important; /* Set the desired border color when the input is focused */
  box-shadow: none !important; /* Optionally, remove the box-shadow that might appear */
}
.custom-input:focus {
  border-color: #F5F5F5 !important; /* Set the desired border color when the input is focused */
  box-shadow: none !important; /* Optionally, remove the box-shadow that might appear */
}

.center-data{
  display: flex;
  justify-content: center;
  align-items: center;
}
.center-x{
  display: flex;
  justify-content: center;
}
.center-y{
  display: flex;
  align-items: center;
}

.MuiTabs-scroller{
  overflow:auto !important ;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color: #000000 !important;
  font-weight: 600 !important;
}

.css-1aquho2-MuiTabs-indicator{
  background-color: #000000 !important;
}

.load-button{
  height: 30px;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #2188E7;
  font-weight: 500;
  color: #F5F5F5;
}
.load-button:hover{
  height: 35px;
  font-size: 20px;
  outline: none;
  background-color: #59AFFF;
  font-weight: 600;
  color:  #8A8C94;
  box-shadow: 0px 1px 1px 0px #59AFFF;
}


.example::-webkit-scrollbar {
  display: none;
}


.example {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}



#notificationHeader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

#notificationBody {
  margin-top: 10px;
  text-align: center;
}

#imageContainer {
  display: flex;
  align-items: center;
  height: 100px;
  object-fit: contain;
}
