@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Lora:ital,wght@0,400..700;1,400..700&family=Press+Start+2P&family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.green {
  /* style={{color:'green',backgroundColor:'green'}} */
  background-color: rgba(0, 128, 0, 0.365);
  color: green;
  border-radius: 50%;
}

.redd {
  background-color: rgb(230, 121, 121);
  color: red;
  border-radius: 50%;
}

.allfamily {
  font-family: "Work Sans";
  font-weight: 600;
}


.brain {
  background: radial-gradient(70.87% 276.64% at 49.13% 50.26%, #E34F4F 0%, #D64A7B 40.57%, #C143BC 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  ;
  background-color: #890e39;
}

.car {
  background: #FFFFFF33;
  width: fit-content;
  border-radius: 5px;
  color: #FFFFFF;
}

.brain2 {
  background: #FFFFFF33;

}

.HistoryView {
  border-radius: 10px;
  border: 1px solid #f0f0f0;
  /* Assuming ColorsConstant.LightWhitee is equivalent to #f0f0f0 */
  height: 65px;
  width: 100%;
  margin-top: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HistoryView1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.HistoryView2 {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
}

.AmountView {}

.AmountText {
  font-family: "WorkSans-SemiBold";
  font-size: 20px;
  color: #000;
  /* Assuming ColorsConstant.Black */
}

.TextDate {
  font-family: "WorkSans-Regular";
  font-size: 12px;
  color: #a9a9a9;
  /* Assuming ColorsConstant.AshGray */
}

.PendingView {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.TextSuccess {
  font-family: "WorkSans-SemiBold";
  font-size: 20px;
  padding-right: 10px;
  color: #008000;
  /* Assuming ColorsConstant.GreenColor */
}

/* styles.css */

.card-container {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
  padding: 16px;
  margin-bottom: 16px;
  border: none;
}

.icon-wrapper {
  margin-right: 16px;
  background-color: rgba(239, 255, 246, 1);
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-wrapper1 {
  margin-right: 16px;
  background-color: rgba(255, 239, 239, 1);
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-wrapper {
  flex: 1;
}

.title {
  font-size: 20px;
  margin-bottom: 10px;
  font-family: Work Sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.15px;
  text-align: left;

}

.subtitle {
  font-size: 14px;
  color: #666666;
  margin: auto;
}

.status-wrapper {
  display: flex;
  align-items: center;
}

.status-icon {
  margin: auto;
  padding: 2px 8px 6px 0px;

}

.status-title {
  font-size: 18px;
  color: 'green';
}

.PaymenyView {
  padding: 0;
  position: absolute;
  bottom: 0;
  height: 50px;
  width: 100%;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PaymentText {
  font-family: 'WorkSans-SemiBold';
  font-size: 30px;
  color: #ffffff;
  /* Assuming ColorsConstant.White resolves to #ffffff */
  margin-top: 10px;
}

.StepView {
  flex: 1;
  /* background-color: #<ColorsConstant.Theme>; Assuming ColorsConstant.Theme resolves to a valid color */
  display: flex;
  align-items: center;
  justify-content: center;
}

.StepView1 {
  flex: 1;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Viewfade {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Viewfade1 {
  width: 80px;
  height: 80px;
  border-width: 5px;
  border-style: solid;
  border-color: #fff;
  border-radius: 50%;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment {
  font-family: 'Work Sans';
  font-size: 32px;
  font-weight: 600;
  line-height: 37.54px;
  text-align: left;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  /* margin-top: 100%;/ */

}

.transaction-wrapper {
  text-align: center;
  background-color: white;
  border: border 2px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  margin: 0 auto;
  padding: 2rem;
  font-family: Arial, sans-serif;
}

.red {
  background-color: rgb(230, 67, 67);
}

.h2 {
  grid-column: span 12;
  margin-bottom: 1rem;

}

.transaction-wrapper p {
  grid-column: span 12;
  margin-bottom: 0.5rem;
  font-size: larger;

}

.transaction-wrapper .rupee-symbol {
  font-size: 1.2rem;
  vertical-align: middle;
}

.transaction-wrapper .product-details {
  grid-column: span 12;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.code {
  font-family: sans-serif;
  font-weight: 600;
}

.code0 {
  font-family: sans-serif;
  font-weight: 500;
}

.code1 {
  font-family: sans-serif;
  font-weight: 500;
  font-size: 24px;
}

.payment-status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.status-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.success-message {
  color: green;
  margin-top: 20px;
}

.failure-message {
  color: red;
  margin-top: 20px;
}

.home-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
}

.home-button:hover {
  background-color: #0056b3;
}


/* @media (max-width: 768px) {
  .example1 {
     padding-bottom: 45px !important;
  } 
} */