.dwd_color {
    background-color: #701DDB;
}
.dwld_app {
    color: white;
    background-color: #d87cff;
    font-size: 25px;
    font-weight: 600;
    padding: 20px;
    border-color: #d87cff;
    cursor: pointer;
    
}