.tags_text_slide {
    font-size: 15px;
    color: #fff;
    font-weight: 600;
    background-color: #baa7a796;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    cursor: pointer;
}
.tags_whole {
    display: flex;
    overflow-x: auto;
    flex-grow: 1;
    margin: 0 10px; /* Adjust the margin to fit properly */
    scrollbar-width: thin; /* For Firefox */
}
.tags_whole::-webkit-scrollbar {
    height: 6px; /* Adjust scrollbar height */
}

.tags_whole::-webkit-scrollbar-thumb {
    background-color: #6f42c1; /* Scrollbar thumb color */
    border-radius: 10px; /* Scrollbar thumb roundness */
}
.contain_tags_text {
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.top_rel_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contain_tags_text_text_slide {
    display: flex;
    align-items: center;
}
.video-player {
    position: relative;
    
    
}

.overlay_reel {
    position: absolute;
    top: -70px;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    
    pointer-events: none;
    box-sizing: border-box;
  /* background-color: rgba(0, 0, 0, 0.5) */
}

.video-details-left {
    color: white;
    pointer-events: auto;
    max-width: 70%;
     background-color: rgba(0, 0, 0, 0.5);
     padding: 10px;
}

.caption_reel {
    font-size: 16px;
    margin-bottom: 5px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tag_reel {
    font-size: 14px;
    color: #ccc;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.video-details-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: auto;
    background-color: #00000080;
     padding: 10px;
}

.icon_like {

    font-size: 24px;
    margin-bottom: 10px;
    cursor: pointer;
}
.icon_comment {
    color: white;
    font-size: 24px;
    margin-bottom: 10px;
    cursor: pointer;
}






.video-container_reel {
   
    height: 90vh;
    scroll-snap-align: start;
    
  }
  .bot_rel_botom {
    scroll-snap-type: y mandatory;
   
  }









  .caption_reel.collapsed,
.tag_reel.collapsed {
    -webkit-line-clamp: 2; /* Limit to 2 lines */
    overflow: hidden; /* Hide excess content */
    text-overflow: ellipsis; /* Add ellipsis to indicate more content */
    display: none;
}

.caption_reel.expanded,
.tag_reel.expanded {
    -webkit-line-clamp: unset; /* Remove the line limit */
    overflow: visible; /* Allow full content to be visible */ 
    display: block;
}

.show-more {
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}
.top_icons_reel {
    flex-basis: 35px; /* Same as your font size */
    flex-shrink: 0; /* Prevent shrinking */
  }

  .error-message-card {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px; /* Set height similar to the video card height */
    background-color: #f8d7da; /* Light red background */
    color: #721c24; /* Dark red text */
    border: 1px solid #f5c6cb; /* Border matching the background */
    border-radius: 10px; /* Rounded corners */
    padding: 20px;
    text-align: center;
    margin-top: 20px;
  }
  
  .error-message-content h2 {
    font-size: 24px; /* Heading font size */
    margin-bottom: 10px;
  }
  
  .error-message-content p {
    font-size: 18px; /* Paragraph font size */
  }
  


  @media (max-width: 576px) {
    .top_icons_reel {
      flex-basis: 30px; /* Adjust as needed */
    }
   
  }
  
