
  
  /* Profile sidebar */
  .profile-sidebar {
    /* padding: 20px 0 10px 0; */
    /* background: #fff; */
    justify-content: center;
    align-items: center;
  }
  
  .profile-userpic img {
    float: none;
    margin: 0 auto;
    width: 50%;
    height: 50%;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    border-radius: 50% !important;
  }
  
  .profile-usertitle {
    text-align: center;
    margin-top: 20px;
  }
  
  .profile-usertitle-name {
    color: #5a7391;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 7px;
  }
  
  .profile-usertitle-job {
    text-transform: uppercase;
    color: #5b9bd1;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  
  .profile-userbuttons {
    text-align: center;
    margin-top: 10px;
  }
  
  .profile-userbuttons .btn {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    padding: 6px 15px;
    margin-right: 5px;
  }
  
  .profile-userbuttons .btn:last-child {
    margin-right: 0px;
  }
      
  .profile-usermenu {
    margin-top: 30px;
  }
  
  .profile-usermenu ul li {
    border-bottom: 1px solid #f0f4f7;
  }
  
  .profile-usermenu ul li:last-child {
    border-bottom: none;
  }
  
  .profile-usermenu ul li a {
    color: #93a3b5;
    font-size: 14px;
    font-weight: 400;
  }
  
  .profile-usermenu ul li a i {
    margin-right: 8px;
    font-size: 14px;
  }
  
  .profile-usermenu ul li a:hover {
    background-color: #fafcfd;
    color: #5b9bd1;
  }
  
  .profile-usermenu ul li.active {
    border-bottom: none;
  }
  
  .profile-usermenu ul li.active a {
    color: #5b9bd1;
    background-color: #f6f9fb;
    border-left: 2px solid #5b9bd1;
    margin-left: -2px;
  }
  
  /* Profile Content */
  .profile-content {
    padding: 20px;
    background: #fff;
    min-height: 460px;
  }
  .imgbg{
    background-image: url('../img/background1.png');
    width: 100%;
    height: fit-content;
    background-repeat: no-repeat;
    background-size: contain;   
  }

  .imgbg1{
    background-image: url('../img/background2.png');
    width: 100%; 
   height: fit-content;
   background-repeat: no-repeat;
    background-size: contain;   

   
  }
  .HelpView {
    width: 100vh;
    height: 60px;
  }
  
  .touchH {
    display: flex;
    flex-direction: row;
    border: 1px solid #000;
    border-radius: 15px;
    justify-content: space-around;
    align-items: center;
  }
  
  .CkrView {
    /* width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0.20; */
  }
/*   
  .Ckrimg {
    width: 30px;
    height: 30px;
  } */
  
  .SupportV {
    /* flex: 0.80; */
  }
  
  .TextSupport {
    font-size: 18px;
    font-family: "WorkSans-Medium";
  }
  .card-container {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #b2b2b2;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 1px 4px 10px -1px rgba(138 131, 131, 0.7);
  }
  .TextSupport{
    font-size: 24px;
  }

  .name {
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.77px;
    text-align: left;
    color: rgba(138, 138, 138, 1);
  }
  .form-control{
    font-family: 'Work Sans';
font-size: 24px;
font-weight: 600;
line-height: 28.15px;

  }

  .img-circle{
    border-radius: 50%;
  }