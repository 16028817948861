.nextButtonClass {
    border-radius: 10px;
    background: rgba(81, 195, 134, 1);
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    border: 0;
}

.alpha {
    font-weight: 600;
    color: rgba(41, 134, 243, 1);

}

.txt {
    font-family: Work Sans;
    font-size: 16px;
    font-weight: 700;
    margin-top: 5px;

}
.custom-carousel .carousel-control-prev,
.custom-carousel .carousel-control-next,
.custom-carousel .carousel-indicators {
  display: none;
}



.see {
    font-family: Work Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.77px;
    text-align: left;
    color: rgba(18, 156, 115, 1);
    ;

}

.some-txt {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    text-wrap: nowrap;
    margin-top: 10px;
}

.txx {
    color: rgba(126, 126, 126, 1);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: left;

}

.new-txt {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: left;
    color: rgba(245, 184, 7, 1);

}

.registerButtonClass {
    border-radius: 7px;
    background: rgba(34, 137, 231, 1);
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    border: 0;

}

.trophy {
    color: rgba(221, 116, 238, 1);
    font-size: 3vh;
}

.persent {
    color: rgba(221, 116, 238, 1);
}

.participateButtonClass {
    border: 1px solid rgba(189, 61, 210, 1);
    color: rgba(189, 61, 210, 1);
    background: rgba(254, 251, 255, 1);
    border-radius: 7px;
}

.number {
    font-family: Work Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 18.77px;
    text-align: left;
    color: black;

}

.bx {
    background: linear-gradient(180deg, #496BC0 0%, #323698 100%);

}

.spns {
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
    color: rgba(255, 255, 255, 0.46);
    padding: 2px;
}

.map {
    color: rgba(73, 107, 192, 1);
}

.fcbk {
    font-weight: 600;
    color: white;

}

.frnd {
    font-family: Inter;
    font-weight: 500;
    line-height: 25px;
    text-align: left;
    color: white;
}

.blu {
    border: 1px solid rgba(54, 124, 255, 1);
    background: rgb(222, 231, 249);
    border-radius: 10px;
    color: rgba(54, 124, 255, 1);
}

.blu1 {
    border: 1px solid #282007;
    background: #f7d3d3;
    border-radius: 10px;
    color: #282007;
}

.grn {
    border: 1px solid rgba(29, 193, 115, 1);
    background: rgb(218, 246, 233);
    border-radius: 10px;
    color: rgba(29, 193, 115, 1);
    ;
}

.viewButtonClass {
    border-radius: 7px;
    background: #fff;
    font-size: 1rem;
    font-weight: 500;
    border: 1px solid #D9D9D9;
}

.num {
    color: rgba(245, 184, 7, 1);

}

.backbtn {
    border-radius: 60%;
    display: flex;
    justify-content: center;
    background-color: rgba(87, 76, 76, 0.5);
}

.prtcpnts {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;

}

.crd {
    color: rgba(126, 126, 126, 1);
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: left;

}

.question {
    font-family: Inter;
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;

}

.rule {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;

}

.agreeregisterButtonClass {
    border-radius: 200px;
    background: rgba(34, 137, 231, 1);
    color: #fff;
    font-weight: 600;
    border: 0;
    font-family: Work Sans;
    font-size: 20px;
    text-align: center;

}

.agreestartButtonClass {
    background: linear-gradient(91.08deg, #DC3DAA 4.74%, #C23596 91.89%);
    color: #fff;
    font-weight: 600;
    border: 0;
    font-family: Work Sans;
    font-size: 20px;
    text-align: center;
    border-radius: 200px;
}

.congrats {
    font-family: Work Sans;
    font-size: 10px;
    font-weight: 600;
    line-height: 28.15px;
    text-align: left;

}

.continueButtonClass {
    background: rgb(222, 231, 249);
    border: 0;
    border: 1px solid rgba(54, 124, 255, 1);
    color: rgba(54, 124, 255, 1);
}

.newww {
    color: rgba(220, 17, 17, 1);
    font-family: Work Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.42px;
    text-align: center;
}

/* @media (max-width: 768px) {
    .bottom {
      height: 25px !important;
    } 
} */