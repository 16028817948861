.top_room {
    height: 87vh;
}
.bot_room {
    height: 14vh;
}
.leave-rmdata{
    display: flex;
    justify-content: center;
    align-items: center;
   
    

}
.btnr2{
    background:  #FFF4F4;
    width: 100%;
    height: 45px;
    font-size: 13px;
    color: #D92828;
    font-weight: 700
    }

    .btn2_room_quiz{
        background: linear-gradient(91.08deg, #54ACFD 4.74%, #2289E7 91.89%);
        width: 100%;
        height: 45px;
        font-size: 13px;
        color: #FFFFFF;
        font-weight: 700
        }
        .btn3_room_quiz{
            background: #1DC173;
            width: 45%;
            height: 45px;
            font-size: 13px;
            color: #FFFFFF;
            font-weight: 700
            }
            .bot_creat_new_rom{
                background: #2188E7;
                width: 100%;
                height: 55px;
                font-size: 19px;
                color: #FFFFFF;
                font-weight: 700;
                }
                .arrow_create_rom {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;  /* Adjust the width and height as needed */
                    height: 40px;
                    background-color: #b39d9d78;
                    border-radius: 50%;
                }


    @media (max-width: 720px) {
        .room_card_height {
            height: 61vh !important;
        }
        .explore_card_height {
            height: 61vh !important;
        }
        button.proceed-button {
            width: 75% !important;
        }
        button.history-btn {
            width: 50% !important;
        }
        button.delete-btn {
            width: 50% !important;
        }
        .main-btn {
            display: flex !important;
        }
        .row.align-items-center.mx-1 {
            width: 100% !important;
        }
        .quiz-scheduler-container {
            width: 100% !important;
        }
    }
