.carousel-control-prev,
.carousel-control-next {
  display: none;
}
.carousel-indicators {
    display: none;
}
/* .slick-track {
    width: 27216px !important;
    opacity: 1  !important;
    display: flex;
} */
button.slick-arrow.slick-prev {
    display: none !important;
}
button.slick-arrow.slick-next {
    display: none !important;
}
/*  */