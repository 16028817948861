/* Global Styles */

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .boxshadow{
    box-shadow: 1px 4px 10px -1px rgba(138 131, 131, 0.7);
  
  }
  
  body {
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    background-color: #f9f9f9;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    color: #212121;
    margin-bottom: 10px;
  }
  
  p {
    margin-bottom: 20px;
  }
  
  a {
    text-decoration: none;
    color: #2BA1FF;
  }
  
  /* Custom Classes */
  
  .homeData {
    padding: 50px 0;
  }
  
  .cardContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
  
  .card {
    width: 100%;
    padding: 30px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .card-image {
    position: relative;
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .card-title {
    margin-bottom: 10px;
  }
  
  .card-body {
    margin-top: 20px;
  }
  
  .registerButtonClass {
    background-color: #2BA1FF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 30px;
    transition: background-color 0.3s;
  }
  
  .registerButtonClass:hover {
    background-color: #1E88E5;
  }
  
  /* Responsive Styles */
  
  @media (max-width: 992px) {
    .cardContainer {
      gap: 20px;
    }
  }
  
  @media (max-width: 768px) {
    .cardContainer {
      gap: 10px;
    }
  }
  
  @media (max-width: 576px) {
    .card {
      padding: 20px;
    }
  
    .card-image {
      height: 200px;
    }
  
    .card-body {
      margin-top: 10px;
    }
  
    .registerButtonClass {
      padding: 5px 15px;
    }
  
  }
  